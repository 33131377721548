<template>
	<ValidationProvider :name="$attrs.name" :rules="rules" v-slot="{ errors }">
		<v-switch
			v-model="inputValue"
			:label="question.options.label[lang]"
			:hint="question.options.hint ? question.options.hint[lang] : ''"
			:color="color.hexa"
			:error-messages="errors"
			:persistent-hint="question.options.persistentHint"
			:hide-details="!question.options.persistentHint"
			class="mb-6 mb-sm-8"
			@change="setValue()"
		></v-switch>
	</ValidationProvider>

</template>

<script>
import {ValidationProvider} from "vee-validate";
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import {EventBus} from "../../../services/Request";

export default {
	name: 'switch-viewer',

	components: {
		ValidationProvider
	},

	props: {
		value: {
			type: [Object],
			default() {
				return {}
			}
		},
		lang: {
			type: [String],
			default: 'fr'
		},
		color: {
			type: [String, Object],
			default: 'primary'
		},
		rules: {
			type: [Object, String],
			default: ""
		},
		questionIndex: {
			type: [Number],
			default: 0
		},
	},

	data: () => ({
		inputValue: ''
	}),

	mounted() {

	},

	created() {
		if (!_.isEmpty(AppStore.rating.questions_answers) && AppStore.rating.questions_answers[this.question.id]) {
			this.inputValue = parseInt(AppStore.rating.questions_answers[this.question.id].content)
		}
	},

	computed: {
		question: {
			get() {
				return this.value;
			},

			set(newValue) {
				this.$emit('input', newValue)
			}
		},
	},

	watch: {},

	methods: {
		setValue() {
			EventBus.$emit('setQuestionAnswer', {
				question: this.question,
				inputValue: this.inputValue,
			})
		}
	}
};
</script>
