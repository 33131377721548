<template>
	<ValidationProvider :name="$attrs.name" :rules="rules" v-slot="{ errors }">
		<div class="text-h4 my-3" v-html="question.options.label[lang]"></div>

		<v-text-field
			:error-messages="errors"
			v-model="inputValue"
			:type="question.options.type"
			:hint="question.options.displayHint ? question.options.hint[lang] : ''"
			:persistent-hint="question.options.persistentHint"
			:outlined="question.options.outlined"
			:solo="question.options.solo"
			:min="question.options.min"
			:max="question.options.max"
			:clear-icon="question.options.clearIcon"
			:clearable="question.options.clearable"
			step="1"
			class="mb-6 mb-sm-8 pt-1"
			:hide-details="!question.options.persistentHint"
			@change="setValue()"
		></v-text-field>
	</ValidationProvider>

</template>

<script>
import {ValidationProvider} from "vee-validate";
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import {EventBus} from "../../../services/Request";

export default {
	name: 'text-viewer',

	components: {
		ValidationProvider
	},

	props: {
		value: {
			type: [Object],
			default() {
				return {}
			}
		},
		lang: {
			type: [String],
			default: 'fr'
		},
		rules: {
			type: [Object, String],
			default: ""
		},
		questionIndex: {
			type: [Number],
			default: 0
		},
	},

	data: () => ({
		inputValue: ''
	}),

	mounted() {

	},

	created() {
		if (!_.isEmpty(AppStore.rating.questions_answers) && AppStore.rating.questions_answers[this.question.id]) {
			this.inputValue = AppStore.rating.questions_answers[this.question.id].content
		}
	},

	computed: {
		question: {
			get() {
				return this.value;
			},

			// set(newValue) {
			// 	this.$emit('input', newValue)
			// }
		},
	},

	watch: {},

	methods: {
		setValue() {
			EventBus.$emit('setQuestionAnswer', {
				question: this.question,
				inputValue: this.inputValue,
			})
		}
	}
};
</script>
