<template>
	<ValidationProvider :rules="rules" v-slot="{ errors }">

		<div class="text-h4 my-3" v-html="question.options.label[lang]"></div>

		<v-select v-model="inputValue"
		          :error-messages="errors"
		          class="pt-1 mb-6 mb-sm-8"
		          :items="question.options.items[lang]"
		          item-text="label"
		          item-value="value"
		          :multiple="question.options.multiple"
		          :chips="question.options.chips"
		          :return-object="question.options.returnObject"
		          :deletable-chips="question.options.deletableChips"
		          :hint="question.options.hint ? question.options.hint[lang] : ''"
		          :placeholder="$t('component.select')"
		          :persistent-hint="question.options.persistentHint"
		          :outlined="question.options.outlined"
		          :solo="question.options.solo"
		          :clear-icon="question.options.clearIcon"
		          :clearable="question.options.clearable"
		          :hide-details="!question.options.persistentHint"
		          flat
		          :menu-props="{ maxHeight: 600 }"
		          @change="setValue()"
		>
			<template v-slot:item='{item}'> <div v-html='item.label'/> </template>
			<template v-slot:selection='{item}'> <div v-html='item.label'/> </template>
		</v-select>
	</ValidationProvider>
</template>

<script>
import {ValidationProvider} from "vee-validate";
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import {EventBus} from "../../../services/Request";

export default {
	name: 'select-viewer',

	components: {
		ValidationProvider
	},

	props: {
		value: {
			type: [Object],
			default() {
				return {}
			}
		},
		questionIndex: {
			type: [Number],
			default: 0
		},
		lang: {
			type: [String],
			default: 'fr'
		},
		rules: {
			type: [Object, String],
			default: ""
		},
	},

	data: () => ({
		inputValue: ""
	}),

	mounted() {

	},

	created() {
		if (!_.isEmpty(AppStore.rating.questions_answers) && AppStore.rating.questions_answers[this.question.id]) {
			this.inputValue = AppStore.rating.questions_answers[this.question.id].content
		}
	},

	computed: {
		question: {
			get() {
				return this.value;
			},

			set(newValue) {
				this.$emit('input', newValue)
			}
		},
	},

	watch: {},

	methods: {
		setValue() {
			EventBus.$emit('setQuestionAnswer', {
				question: this.question,
				inputValue: this.inputValue,
			})
		}
	}
};
</script>
<style>
.v-select i {
	font-size: 1.8rem !important;
}
</style>